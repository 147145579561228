.root
  min-width: 300px
  display: grid
  grid-template-columns: auto 1fr auto
  grid-template-rows: auto 1fr 1fr
  &[data-variant=pending]
    .icon
      path
        fill: var(--text-color-secondary)

.progress
  grid-column: 1 / span 3
  grid-row: 1
  color: var(--button-background-color-primary)
  border-radius: 6px 6px 0 0
  height: 4px

.icon
  grid-column: 1
  grid-row: 2 / span 2
  align-self: center
  width: 20px
  height: 20px
  margin-left: 20px
  margin-right: 20px
  
.text
  grid-column: 2
  grid-row: 2
  align-self: center
  margin-bottom: 4px
  margin-top: 20px
  margin-right: 20px

.link
  grid-column: 2
  grid-row: 3
  align-self: center
  margin-top: 4px
  margin-bottom: 20px
  margin-right: 20px

.close
  grid-column: 3
  grid-row: 2 / span 2
  align-self: center
  cursor: pointer
  width: 12px
  height: 12px
  margin-right: 20px