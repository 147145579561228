.pill
  font-weight: 600
  font-size: 12px
  text-transform: uppercase
  padding: 4px 8px
  gap: 10px
  border-radius: 20px
  line-height: 20px

  &_green
    background: var(--pill-background-green)
    color: var(--color-light-green)

  &_blue
    background: var(--pill-background-blue)
    color: var(--color-blue)

  &_disabled
    background: var(--button-background-color-secondary-disabled)
    color: var(--button-color-secondary-disabled)
