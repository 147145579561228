// Main header navigation styles
.navigation-link {
    background-color: transparent;
    border-radius: 60px;
    padding: 8px 25px;
    text-align: center;
    text-decoration: none;
    
    .navigation-text {
        transition: all 0.2s ease;
    }

    &:hover .navigation-text {
        color: $electricblue-color;
    }
}

.navigation-link.active {
    background-color: $electricblue-color;

    .navigation-text,
    &:hover .navigation-text {
        color: $white-text;
    }
}

.navigation-drawer-container {
    padding: 24px;
    box-shadow: $primary-shadow;
}

// Tab styles
.MuiTab-root {
    @extend .MuiTypography-root;
    @extend .MuiTypography-body1;
    @extend .navigation-link;
    font-family: $font-primary;
    color: $black-text;
    text-transform: none;
    transition: color 0.2s ease;

    &.Mui-selected {
        background-color: $electricblue-color;
        color: $white-text;

        &:hover {
            color: $white-text;
        }
    }

    &:hover {
        color: $electricblue-color;
    }
}

@include breakpoint(tablet) {
	.navigation-link {
		margin: 8px 0;
	}
    
    .navigation-drawer-item-container {
        min-height: calc(100vh - 55px - 48px);
    }
}
