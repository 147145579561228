.root
    display: flex
    align-items: center
    .dot
        border-radius: 50%
        transform: scale(0.3)
        margin-left: 2px
        margin-right: 2px
        &:nth-child(1)
            animation: load 1.2s infinite ease-in-out
            animation-delay: 0.0s
        &:nth-child(2)
            animation: load 1.2s infinite ease-in-out
            animation-delay: 0.2s
        &:nth-child(3)
            animation: load 1.2s infinite ease-in-out
            animation-delay: 0.4s
    .small
        width: 8px
        height: 8px
    .large
        width: 12px
        height: 12px
    .primary
        background-color: var(--button-background-color-primary)
    .secondary
        background-color: var(--text-color-primary)
    .error
        background-color: var(--text-color-error)

@keyframes load
    0%
        transform: scale(0.3)
        opacity: 0.3
    50%
        transform: scale(1)
        opacity: 1
    0%
        transform: scale(0.3)
        opacity: 0.3
