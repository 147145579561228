.root
  width: 520px
  z-index: 10000
  margin: auto
  min-width: 520px
  background-color: var(--modal-background-color)
  &:focus-visible
    outline: none

.form
  padding: 40px 32px 32px 32px
  row-gap: 24px !important

.title
  margin-bottom: 30px

.name
  margin-bottom: 0px !important

.formButtons
  border-top: 2px solid var(--background-color)
  padding: 32px

  button
    margin-left: 8px
    margin-right: 8px
    &:first-child
      margin-left: 0
    &:last-child
      margin-right: 0
