/* Colors Base
================================= */
$electricblue-color: #1282FD;
$blue-color: #2C89C7;
$royalblue-color: #4D58FF;
$turquoise-color: #1690B9;
$purple-color: #A94F9C;
$lavender-color: #8059A7;
$dustypink-color: #DB9BC4;
$red-color: #EA4343;
$brick-color: #E4761B;
$mocha-color: #E7AE82;
$pink-color: #FFAAB6;
$lilac-color: #D7B8F2;
$softblue-color: #D7E8F1;
$softgrey-color: #F6F6F6;
$grey-color: #E8E8E8;
$darkgrey-color: #D9D9D9;
$primary-gradient-color: linear-gradient(276.1deg, $royalblue-color 4.29%, rgba(77, 88, 255, 0) 25.97%), linear-gradient(100.52deg, $dustypink-color -4.99%, rgba(219, 155, 196, 0) 69.14%), $electricblue-color;

$primary-shadow: 0px 0px 20px rgba(28, 28, 28, 0.1);
$soft-shadow: 0px 5px 15px rgba(28, 28, 28, 0.07);
$inset-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.07);
$alternative-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

$black-text: #333333;
$white-text: #FFFFFF;
$grey-text: #979797;

$grey-border: #C8C8C8;

// Snackbar
$green-background: #DFF1D9;
$green-background-text: #1F8B24;
$red-background: #FAE1DB;
$red-background-text: #D91009;
$grey-background: #F0ECE8;
$grey-background-text: #605F5C;
$yellow-background: #FFF3CD;
$yellow-background-text: #E6AB0A;

// All these down below are mock colors (not finalized)
$primary-color: #C3B1E1;
$secondary-color: #F1F1F1;

$neon-green: #5CFA46;
$purple: #3D056B;
$blue: #4862D8;

$background-color: #CDD1D8;
