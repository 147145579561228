/* Typography Base
================================= */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

// Font family
$font-primary: 'Open Sans', sans-serif;

// Font weight
$font-weight-regular: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;

// Font size
$font-size-h1: 48px;
$font-size-h2: 32px;
$font-size-h3: 26px;
$font-size-h4: 20px;
$font-size-button: 20px;
$font-size-subtitle1: 18px;
$font-size-body1: 16px;
$font-size-body2: 14px;
$font-size-body3: 14px;
$font-size-body4: 12px;
$font-size-caption: 12px;

// Typography variant (class is based on MUI's documentation)
.MuiTypography-root {
    font-family: $font-primary;
    font-weight: $font-weight-regular;
    font-size: 16px;
    color: $black-text;
    letter-spacing: -0.03em;
}

.MuiTypography-h1 {
    font-weight: $font-weight-bold;
    font-size: $font-size-h1;
}

.MuiTypography-h2 {
    font-weight: $font-weight-bold;
    font-size: $font-size-h2;
}

.MuiTypography-h3 {
    font-weight: $font-weight-bold;
    font-size: $font-size-h3;
}

.MuiTypography-h4 {
    font-weight: $font-weight-bold;
    font-size: $font-size-h4;
}

.MuiTypography-button {
    font-weight: $font-weight-bold;
    font-size: $font-size-button;
    text-transform: none;
}

.MuiTypography-subtitle1 {
    font-weight: $font-weight-bold;
    font-size: $font-size-subtitle1;
}

.MuiTypography-body1 {
    font-weight: $font-weight-semibold;
    font-size: $font-size-body1;
}

.MuiTypography-body2 {
    font-weight: $font-weight-semibold;
    font-size: $font-size-body2;
}

.MuiTypography-body3 {
    font-weight: $font-weight-regular;
    font-size: $font-size-body3;
}

.MuiTypography-body4 {
    font-weight: $font-weight-semibold;
    font-size: $font-size-body4;
}

.MuiTypography-caption {
    font-weight: $font-weight-semibold;
    font-size: $font-size-caption;
    color: $grey-text;
}

.label {
    color: $grey-text
}

.green-text {
    color: $green-background-text;
}

.red-text {
    color: $red-background-text;
}

.link-text {
    @extend .MuiTypography-body1;
    color: $purple-color;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        border-bottom: 1px solid $purple-color;
    }
}