.MuiInput-root,
.MuiInputBase-root,
input {
    font-family: $font-primary !important;
}

.input-base {
    box-shadow: $inset-shadow;

    input {
        color: $black-text;
    }
}

.input-slippage-container {
    width: 70px;
    height: 32px;
    border-radius: 8px;

    .input-slippage {
        border-radius: 8px;
        padding: 4px 10px;
        font-size: $font-size-body3;
        font-weight: $font-weight-regular;
    }
}

.input-search-container {
    background-color: $softgrey-color;
    border-radius: 32px;
    height: 50px;

    svg {
        padding-right: 24px;

        path {
            fill: $grey-text;
        }
    }

    .input-search {
        border: 0;
        padding: 16px 24px;
        font-size: $font-size-body1;
        font-weight: $font-weight-semibold;
    }
}

.MuiSlider-root {
    p, span {
        font-family: $font-primary;
    }
}
