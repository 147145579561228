.modal-base,
.wallet-wc-modal--content {
    // Adding !important because overriding Wallet Connect's styles
    padding: 25px !important;
    border-radius: 16px !important;
    box-shadow: $primary-shadow !important;
    width: 400px;
    max-width: 500px;

    &.w-450 {
        width: 450px;
    }
}

.modal-base-background,
.wallet-wc-modal--dim {
    // Adding !important because overriding Wallet Connect's styles
    background-color: rgba(28, 28, 28, 0.4) !important;
}

.modal-close-button {
    position: absolute;
    top: 20px;
    right: 25px;
    width: 32px;
    height: 32px;
    border: 1px solid $black-text;
    border-radius: 50%;
    display: flex;
    min-width: 0px;
    z-index: 1;
    transition: all 0.1s ease;

    &:hover {
        border-width: 2px;
        background-color: transparent;
    }
}

@include breakpoint(mobile) {
    .modal-base,
    .wallet-wc-modal--content {
        // On mobile, modal will be full screen with 40px space on right and left (total 80px)
        min-width: calc(100vw - 80px);
        max-width: calc(100vw - 80px);
        margin: 0;

        &.w-450 {
            min-width: calc(100vw - 80px);
        }
    }
}
