.button-connect-wallet {
    background-color: $softblue-color;
    border: 1px solid $blue-color;

    svg {
        width: 20px;
    }

    &:hover {
        background-color: $softblue-color;

        p,
        svg path {
            color: $blue-color;
            fill: $blue-color;
        }
    }
}

.button-connected-wallet {
    text-transform: unset;
    border-radius: 30px;
}

.button-wallet-options {
    display: unset;
    border-radius: 8px;
    padding: 10px;
    box-shadow: none;

    &:hover {
        background-color: $softblue-color;
        border: none;
        box-shadow: none;
    }
}

.dropdown-connected-wallet {
    box-shadow: $primary-shadow;
    border-radius: 16px;
    min-width: 250px;
    margin-top: 16px;

    .dropdown-connected-wallet-menu-item:hover {
        background: $grey-color;
        transition: all 0.2s ease;
    }
}

.icon-wallet {
    width: 32px;
}

.vertical-divider {
    border-right: 1px solid $blue-color;
}
