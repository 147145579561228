.card-container {
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(3px);
    border-radius: 16px;
    box-shadow: $primary-shadow;
    margin-bottom: 36px;
    
    &.w-550 {
        width: 550px;
    }

    &.fixed-bottom {
        position: fixed;
        bottom: 16px;
    }
}

@include breakpoint(mobile) {
    .card-container {
        &.w-550 {
            width: 100%
        }

        &.fixed-bottom {
            bottom: 0;
            margin-bottom: 0;
            border-radius: 0;
        }
    }

    .card-stats-item:not(:last-child) {
        margin-bottom: 24px;
    }
}
