.root
  display: flex

.content
  z-index: 10000
  margin: auto
  min-width: 520px
  background-color: var(--modal-background-color)
  &:focus-visible
    outline: none
   