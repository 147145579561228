.root
  font-family: PolySans
  color: var(--input-color)
  &:global(.MuiInputBase-root)
    font-family: PolySans
    color: var(--input-color)
    background-color: var(--input-background-color)
    & :global(.MuiInputBase-input)
      display: flex
      padding: 16px 14px
      min-height: 24px
      line-height: 24px
  &:global(.MuiOutlinedInput-root)
    & fieldset
      border-width: 1px
      border-color: var(--input-background-color)
    &:hover fieldset 
      border-color: var(--input-background-color)
    &:global(.Mui-focused) fieldset
      border-width: 1px
      border-color: var(--input-border-color-focus)
    &:global(.Mui-error) fieldset
      border-width: 1px
      border-color: var(--input-border-color-error)

.placeholder
  color: var(--input-placeholder-color)

.icon
  fill: var(--text-color-secondary) !important

.menu
  font-family: PolySans
  :global(.MuiPaper-root)
    box-shadow: none
    background-color: var(--popup-background-color)
    border-radius: 6px
    :global(.MuiMenu-list)
      color: var(--input-color)
      li
        font-family: PolySans
        &:not([data-value])
          color: var(--text-color-secondary)
        
        &:global(.Mui-selected)
          background-color: rgba(0, 0, 0, 0.1)
