.root
  display: flex
  border-radius: 6px
  font-family: PolySans
  font-size: 14px
  font-weight: 600
  display: flex
  flex-direction: row
  align-items: center
  justify-content: center
  cursor: pointer
  white-space: nowrap
  height: 44px
  min-height: 44px
  outline: none
  border: none
  min-width: 90px
  &[disabled]
    cursor: pointer !important

.primary
  &.fill
    color: var(--button-color-primary)
    background-color: var(--button-background-color-primary)
    svg 
      path
        fill: var(--button-color-primary)
    &:hover
      background-color: var(--button-background-color-primary-hover)
    &:active
      background-color: var(--button-background-color-primary-pressed)
    &[disabled]
      color: var(--button-color-primary-disabled)
      background-color: var(--button-background-color-primary-disabled)
      svg 
        path
          fill: var(--button-color-primary-disabled)
    .throbber
      background-color: var(--button-background-color-secondary) !important
  &:not(.fill)
    color: var(--button-background-color-primary)
    background-color: transparent
    &:hover
      color: var(--button-background-color-primary-hover)
      svg 
        path
          fill: var(--button-background-color-primary-hover)
    &:active
      color: var(--button-background-color-primary-pressed)
      svg 
        path
          fill: var(--button-background-color-primary-pressed)
    &[disabled]
      color: var(--button-background-color-primary-disabled)
      svg 
        path
          fill: var(--button-background-color-primary-disabled)
    .throbber
      background-color: var(--button-background-color-primary) !important

.secondary
  &.fill
    color: var(--button-color-secondary)
    background-color: var(--button-background-color-secondary)
    svg 
      path
        fill: var(--button-color-secondary)
    &:hover
      background-color: var(--button-background-color-secondary-hover)
    &:active
      background-color: var(--button-background-color-secondary-pressed)
    &[disabled]
      color: var(--button-color-secondary-disabled)
      background-color: var(--button-background-color-secondary-disabled)
      svg 
        path
          fill: var(--button-color-secondary-disabled)
  &:not(.fill)
    color: var(--button-background-color-secondary)
    background-color: transparent
    svg 
      path
        fill: var(--button-background-color-secondary)
    &:hover
      color: var(--button-background-color-secondary-hover)
      svg 
        path
          fill: var(--button-background-color-secondary-hover)
    &:active
      color: var(--button-background-color-secondary-pressed)
      svg 
        path
          fill: var(--button-background-color-secondary-pressed)
    &[disabled]
      color: var(--button-background-color-secondary-disabled)
      svg 
        path
          fill: var(--button-background-color-secondary-disabled)

.danger
  &.fill
    color: var(--button-color-primary)
    background-color: var(--text-color-error)
    svg 
      path
        fill: var(--button-color-primary)
    &:hover
      background-color: darken(#EB5166, 10)
    &:active
      background-color: darken(#EB5166, 20)
    &[disabled]
      background-color: darken(#EB5166, 30)
  &:not(.fill)
    color: var(--button-background-color-secondary)
    background-color: transparent
    &:hover
      color: var(--button-background-color-secondary-hover)
      svg 
        path
          fill: var(--button-background-color-secondary-hover)
    &:active
      color: var(--button-background-color-secondary-pressed)
      svg 
        path
          fill: var(--button-background-color-secondary-pressed)
    &[disabled]
      color: var(--button-background-color-secondary-disabled)
      svg 
        path
          fill: var(--button-background-color-secondary-disabled)

.guttersSmall
  padding: 0 8px

.guttersLarge
  padding: 0 16px

.iconAlignmentStart.iconGapSmall
  *
    &:first-child
      margin-right: 8px

.iconAlignmentStart.iconGapLarge
  *
    &:first-child
      margin-right: 16px

.iconAlignmentEnd.iconGapSmall
  *
    &:last-child
      margin-left: 8px

.iconAlignmentEnd.iconGapLarge
  *
    &:last-child
      margin-left: 16px
