.root
  font-family: PolySans
  color: var(--input-color)
  :global(.MuiInputBase-root)
    font-family: PolySans
    background-color: var(--input-background-color)
  :global(.MuiInputBase-input)
    // padding: 16px 14px
    min-height: 24px
    line-height: 24px
    color: var(--input-color)
    &::placeholder
      opacity: 1
      color: var(--input-placeholder-color)
    &:-ms-input-placeholder
      opacity: 1
      color: var(--input-placeholder-color)
    &::-ms-input-placeholder
      opacity: 1
      color: var(--input-placeholder-color)
  :global(.MuiOutlinedInput-root)
    & fieldset
      border-width: 1px
      border-color: var(--input-background-color)
    &:hover fieldset 
      border-color: var(--input-background-color)
    &:global(.Mui-focused) fieldset
      border-width: 1px
      border-color: var(--input-border-color-focus)
    &:global(.Mui-error) fieldset
      border-width: 1px
      border-color: var(--input-border-color-error)
  :global(.MuiFormHelperText-root)
    margin: 4px 0 0 0
    font-family: PolySans
    color: var(--text-color-secondary)
    &:global(.Mui-error)
      color: var(--text-color-error)
