.footer-container {
    background-color: rgba(35, 35, 35, 0.74);

    * {
        color: $white-text;
    }
}

.logo-dexter-footer {
    width: 60px;
    height: 60px;
}

.logo-dexter-text-footer {
    width: 120px;
    margin-top: 16px;

    path {
        fill: $white-text;
    }
}

.external-link-footer {
    text-decoration: none;
    transition: all 0.2s ease;

    svg {
        font-size: $font-size-h3;
        color: $white-text;
        margin: 0 8px;

        path {
            transition: all 0.2s ease;
        }
    }

    &:hover {
        opacity: 0.7;
    }
}

@include breakpoint(tablet) {
	.external-link-footer {
		svg {
            color: $black-text;
        }
	}
}
