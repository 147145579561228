body {
    background-image: url('~assets/backgrounds/gradient-bg.svg');
    background-size: cover;
    background-repeat: no-repeat;
    margin: 0;
}

#root {
    background-image: url('~assets/backgrounds/crypto-space-faded.svg');
    background-repeat: no-repeat;
    background-position: right -10vw bottom 40%;
    position: relative;
}

.app-body-container {
    // This calculation is to push the footer to always be at the bottom when the app body / content cannot fill the full screen
    // 200px so that the footer will still be visible, the footer height is ~350px
    min-height: calc(100vh - 200px);
}

.info-panel-top {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba($yellow-background, 0.8);
    padding: 16px;
    text-align: center;

    p {
        color: $yellow-background-text;
    }
}

.info-panel-red {
    background-color: $red-background;
    border: 1px solid $red-color;
    border-radius: 8px;
    padding: 16px;
}

.particle-background {
	position: absolute;
    top: 0;
    left: 0;
 	border-radius: 50%;
    z-index: -1;
}

@for $i from 1 through 30 {
	@keyframes particle-animation-#{$i} {
		100% {
			transform: translate3d((random(90) * 1vw), (random(90) * 1vh), (random(100) * 1px));
		}
	}
	
	.particle-background:nth-child(#{$i}){
		animation: particle-animation-#{$i} 60s infinite;
		$size: random(5) + 5 + px;
		opacity: calc(random(100) / 100);
		height: $size;
		width: $size;
		animation-delay: -$i * .2s;
		transform: translate3d((random(90) * 1vw), (random(90) * 1vh), (random(100) * 1px));
        background-color: $electricblue-color;
	}
}

@include breakpoint(laptop) {
    #root {
        background-position: right -14vw bottom 40%;
    }
}

@include breakpoint(tablet) {
	body {
        min-height: calc(100vh - 40px);
    }

    #root {
        background-image: none;
    }
}
