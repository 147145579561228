.table-base {
    font-family: $font-primary;
    border: 0;

    .MuiDataGrid-columnHeader:focus,
    .MuiDataGrid-cell:focus,
    .MuiDataGrid-cell:focus-within {
        outline: none;
    }

    .MuiDataGrid-columnHeaderTitle {
        @extend .MuiTypography-body1;
    }

    .MuiDataGrid-columnHeader--sorted {
        color: $electricblue-color;

        svg path {
            fill: $electricblue-color;
        }
    }

    .MuiDataGrid-columnSeparator {
        display: none;
    }

    .MuiDataGrid-cell {
        border-bottom: none;
        @extend .MuiTypography-body2;
    }

    .MuiDataGrid-row {
        transition: all 0.5s ease;

        &:hover {
            background-color: $grey-color;
        }
    }

    .MuiTablePagination-root {
        p, span {
            font-family: $font-primary;
            @extend .MuiTypography-caption;
        }
    }
}

@include breakpoint(tablet) {
	.table-base {
        width: 900px;
        
        .MuiDataGrid-footerContainer {
            // Move the pagination to the left side
            justify-content: flex-start;
        }
    }
}

@include breakpoint(mobile) {
	.table-base {
        width: 600px;
    }
}
