.tooltip-trigger:hover {
    path, circle {
        transition: all 0.2s ease;
    }
    
    &:hover {
        path {
            fill: $purple-color;
        }
        circle {
            stroke: $purple-color;
        }
    }
}

.tooltip-base {
    padding: 16px;
    background-color: $lavender-color;
    box-shadow: $primary-shadow;

    .MuiTooltip-arrow:before {
        background-color: $lavender-color;
    }

    p, span {
        color: $white-text;
    }
}