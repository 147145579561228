.text-skeleton {
    display: flex;
    background-color: $softgrey-color;
    height: 20px;
    width: 100px;
    border-radius: 8px;
    overflow: hidden;

    &::after {
        content: '';
        height: 100%;
        width: 100px;
        display: block;
        background: linear-gradient(to right, rgba(230,230,230,0) 0%,rgba(221,221,221,0.4) 42%,rgba(221,221,221,0.4) 62%,rgba(230,230,230,0) 100%);
        animation: shining 1.2s ease-out infinite;
    }
}
  
@keyframes shining {
    0% {
      transform: translateX(-100%);
    }
    
    100% {
      transform: translateX(200%);
    }
}

.spinning-logo {
    animation: spin 1.5s 0.3s cubic-bezier(0.17, 0.37, 0.43, 0.67) infinite;

    &.sm {
        width: 24px;
        height: 24px;
    }

    &.lg {
        width: 40px;
        height: 40px;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(180deg);
    }

    100% {
        transform: rotate(360deg);
    }
}