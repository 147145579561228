.logo-dexter {
    width: 45px;
    height: 45px;
    transition: all 1s ease;
}

.logo-dexter-text {
    width: 90px;
    margin-left: 8px;
}

.logo-container:hover .logo-dexter {
    transform: rotate(180deg);
}
