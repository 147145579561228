.snackbar-container {
    padding: 16px 24px;
    border-radius: 30px;
}

.snackbar-success {
    background-color: $green-background;
    color: $green-background-text;

    p, a {
        color: $green-background-text;
    }
}

.snackbar-error {
    background-color: $red-background;
    color: $red-background-text;
}

.snackbar-neutral {
    background-color: $grey-background;
    color: $grey-background-text;
}