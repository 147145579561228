/* Main File for Styles
/* This is the file that will be converted to CSS
================================= */
/* Base
================================= */
/* Colors Base
================================= */
/* Typography Base
================================= */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");
.MuiTypography-root, .MuiTab-root {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
  letter-spacing: -0.03em;
}

.MuiTypography-h1 {
  font-weight: 700;
  font-size: 48px;
}

.MuiTypography-h2 {
  font-weight: 700;
  font-size: 32px;
}

.MuiTypography-h3, .token-selector-input {
  font-weight: 700;
  font-size: 26px;
}

.MuiTypography-h4 {
  font-weight: 700;
  font-size: 20px;
}

.MuiTypography-button {
  font-weight: 700;
  font-size: 20px;
  text-transform: none;
}

.MuiTypography-subtitle1 {
  font-weight: 700;
  font-size: 18px;
}

.MuiTypography-body1, .MuiTab-root, .table-base .MuiDataGrid-columnHeaderTitle, .link-text {
  font-weight: 600;
  font-size: 16px;
}

.MuiTypography-body2, .table-base .MuiDataGrid-cell {
  font-weight: 600;
  font-size: 14px;
}

.MuiTypography-body3 {
  font-weight: 400;
  font-size: 14px;
}

.MuiTypography-body4 {
  font-weight: 600;
  font-size: 12px;
}

.MuiTypography-caption, .table-base .MuiTablePagination-root p, .table-base .MuiTablePagination-root span {
  font-weight: 600;
  font-size: 12px;
  color: #979797;
}

.label {
  color: #979797;
}

.green-text {
  color: #1F8B24;
}

.red-text {
  color: #D91009;
}

.link-text {
  color: #A94F9C;
  text-decoration: none;
  cursor: pointer;
}
.link-text:hover {
  border-bottom: 1px solid #A94F9C;
}

/* Breakpoints Media Query
================================= */
/* Common / Global Components
================================= */
body {
  background-image: url("~assets/backgrounds/gradient-bg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0;
}

#root {
  background-image: url("~assets/backgrounds/crypto-space-faded.svg");
  background-repeat: no-repeat;
  background-position: right -10vw bottom 40%;
  position: relative;
}

.app-body-container {
  min-height: calc(100vh - 200px);
}

.info-panel-top {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 243, 205, 0.8);
  padding: 16px;
  text-align: center;
}
.info-panel-top p {
  color: #E6AB0A;
}

.info-panel-red {
  background-color: #FAE1DB;
  border: 1px solid #EA4343;
  border-radius: 8px;
  padding: 16px;
}

.particle-background {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  z-index: -1;
}

@keyframes particle-animation-1 {
  100% {
    transform: translate3d(90vw, 25vh, 47px);
  }
}
.particle-background:nth-child(1) {
  animation: particle-animation-1 60s infinite;
  opacity: 0.97;
  height: 9px;
  width: 9px;
  animation-delay: -0.2s;
  transform: translate3d(89vw, 19vh, 34px);
  background-color: #1282FD;
}

@keyframes particle-animation-2 {
  100% {
    transform: translate3d(62vw, 35vh, 56px);
  }
}
.particle-background:nth-child(2) {
  animation: particle-animation-2 60s infinite;
  opacity: 0.52;
  height: 9px;
  width: 9px;
  animation-delay: -0.4s;
  transform: translate3d(90vw, 89vh, 88px);
  background-color: #1282FD;
}

@keyframes particle-animation-3 {
  100% {
    transform: translate3d(38vw, 69vh, 76px);
  }
}
.particle-background:nth-child(3) {
  animation: particle-animation-3 60s infinite;
  opacity: 0.42;
  height: 7px;
  width: 7px;
  animation-delay: -0.6s;
  transform: translate3d(8vw, 12vh, 96px);
  background-color: #1282FD;
}

@keyframes particle-animation-4 {
  100% {
    transform: translate3d(81vw, 79vh, 59px);
  }
}
.particle-background:nth-child(4) {
  animation: particle-animation-4 60s infinite;
  opacity: 0.84;
  height: 9px;
  width: 9px;
  animation-delay: -0.8s;
  transform: translate3d(56vw, 79vh, 66px);
  background-color: #1282FD;
}

@keyframes particle-animation-5 {
  100% {
    transform: translate3d(34vw, 18vh, 38px);
  }
}
.particle-background:nth-child(5) {
  animation: particle-animation-5 60s infinite;
  opacity: 0.87;
  height: 6px;
  width: 6px;
  animation-delay: -1s;
  transform: translate3d(69vw, 81vh, 6px);
  background-color: #1282FD;
}

@keyframes particle-animation-6 {
  100% {
    transform: translate3d(76vw, 50vh, 69px);
  }
}
.particle-background:nth-child(6) {
  animation: particle-animation-6 60s infinite;
  opacity: 0.71;
  height: 10px;
  width: 10px;
  animation-delay: -1.2s;
  transform: translate3d(67vw, 63vh, 31px);
  background-color: #1282FD;
}

@keyframes particle-animation-7 {
  100% {
    transform: translate3d(42vw, 53vh, 71px);
  }
}
.particle-background:nth-child(7) {
  animation: particle-animation-7 60s infinite;
  opacity: 0.68;
  height: 9px;
  width: 9px;
  animation-delay: -1.4s;
  transform: translate3d(27vw, 11vh, 14px);
  background-color: #1282FD;
}

@keyframes particle-animation-8 {
  100% {
    transform: translate3d(34vw, 44vh, 89px);
  }
}
.particle-background:nth-child(8) {
  animation: particle-animation-8 60s infinite;
  opacity: 0.74;
  height: 6px;
  width: 6px;
  animation-delay: -1.6s;
  transform: translate3d(61vw, 48vh, 81px);
  background-color: #1282FD;
}

@keyframes particle-animation-9 {
  100% {
    transform: translate3d(76vw, 26vh, 88px);
  }
}
.particle-background:nth-child(9) {
  animation: particle-animation-9 60s infinite;
  opacity: 0.72;
  height: 9px;
  width: 9px;
  animation-delay: -1.8s;
  transform: translate3d(7vw, 90vh, 64px);
  background-color: #1282FD;
}

@keyframes particle-animation-10 {
  100% {
    transform: translate3d(47vw, 51vh, 100px);
  }
}
.particle-background:nth-child(10) {
  animation: particle-animation-10 60s infinite;
  opacity: 0.67;
  height: 6px;
  width: 6px;
  animation-delay: -2s;
  transform: translate3d(17vw, 62vh, 13px);
  background-color: #1282FD;
}

@keyframes particle-animation-11 {
  100% {
    transform: translate3d(24vw, 15vh, 19px);
  }
}
.particle-background:nth-child(11) {
  animation: particle-animation-11 60s infinite;
  opacity: 0.35;
  height: 10px;
  width: 10px;
  animation-delay: -2.2s;
  transform: translate3d(36vw, 8vh, 48px);
  background-color: #1282FD;
}

@keyframes particle-animation-12 {
  100% {
    transform: translate3d(77vw, 30vh, 36px);
  }
}
.particle-background:nth-child(12) {
  animation: particle-animation-12 60s infinite;
  opacity: 0.41;
  height: 9px;
  width: 9px;
  animation-delay: -2.4s;
  transform: translate3d(51vw, 38vh, 53px);
  background-color: #1282FD;
}

@keyframes particle-animation-13 {
  100% {
    transform: translate3d(90vw, 31vh, 11px);
  }
}
.particle-background:nth-child(13) {
  animation: particle-animation-13 60s infinite;
  opacity: 0.79;
  height: 6px;
  width: 6px;
  animation-delay: -2.6s;
  transform: translate3d(51vw, 34vh, 35px);
  background-color: #1282FD;
}

@keyframes particle-animation-14 {
  100% {
    transform: translate3d(19vw, 36vh, 24px);
  }
}
.particle-background:nth-child(14) {
  animation: particle-animation-14 60s infinite;
  opacity: 0.1;
  height: 6px;
  width: 6px;
  animation-delay: -2.8s;
  transform: translate3d(18vw, 20vh, 91px);
  background-color: #1282FD;
}

@keyframes particle-animation-15 {
  100% {
    transform: translate3d(65vw, 61vh, 72px);
  }
}
.particle-background:nth-child(15) {
  animation: particle-animation-15 60s infinite;
  opacity: 0.59;
  height: 7px;
  width: 7px;
  animation-delay: -3s;
  transform: translate3d(39vw, 62vh, 61px);
  background-color: #1282FD;
}

@keyframes particle-animation-16 {
  100% {
    transform: translate3d(87vw, 82vh, 9px);
  }
}
.particle-background:nth-child(16) {
  animation: particle-animation-16 60s infinite;
  opacity: 0.65;
  height: 8px;
  width: 8px;
  animation-delay: -3.2s;
  transform: translate3d(80vw, 7vh, 54px);
  background-color: #1282FD;
}

@keyframes particle-animation-17 {
  100% {
    transform: translate3d(18vw, 49vh, 39px);
  }
}
.particle-background:nth-child(17) {
  animation: particle-animation-17 60s infinite;
  opacity: 0.14;
  height: 9px;
  width: 9px;
  animation-delay: -3.4s;
  transform: translate3d(44vw, 59vh, 16px);
  background-color: #1282FD;
}

@keyframes particle-animation-18 {
  100% {
    transform: translate3d(44vw, 21vh, 61px);
  }
}
.particle-background:nth-child(18) {
  animation: particle-animation-18 60s infinite;
  opacity: 0.5;
  height: 9px;
  width: 9px;
  animation-delay: -3.6s;
  transform: translate3d(87vw, 40vh, 22px);
  background-color: #1282FD;
}

@keyframes particle-animation-19 {
  100% {
    transform: translate3d(5vw, 17vh, 23px);
  }
}
.particle-background:nth-child(19) {
  animation: particle-animation-19 60s infinite;
  opacity: 0.84;
  height: 9px;
  width: 9px;
  animation-delay: -3.8s;
  transform: translate3d(54vw, 37vh, 65px);
  background-color: #1282FD;
}

@keyframes particle-animation-20 {
  100% {
    transform: translate3d(4vw, 81vh, 90px);
  }
}
.particle-background:nth-child(20) {
  animation: particle-animation-20 60s infinite;
  opacity: 0.48;
  height: 9px;
  width: 9px;
  animation-delay: -4s;
  transform: translate3d(83vw, 86vh, 8px);
  background-color: #1282FD;
}

@keyframes particle-animation-21 {
  100% {
    transform: translate3d(56vw, 49vh, 23px);
  }
}
.particle-background:nth-child(21) {
  animation: particle-animation-21 60s infinite;
  opacity: 0.99;
  height: 8px;
  width: 8px;
  animation-delay: -4.2s;
  transform: translate3d(18vw, 8vh, 20px);
  background-color: #1282FD;
}

@keyframes particle-animation-22 {
  100% {
    transform: translate3d(73vw, 41vh, 60px);
  }
}
.particle-background:nth-child(22) {
  animation: particle-animation-22 60s infinite;
  opacity: 0.35;
  height: 6px;
  width: 6px;
  animation-delay: -4.4s;
  transform: translate3d(46vw, 65vh, 52px);
  background-color: #1282FD;
}

@keyframes particle-animation-23 {
  100% {
    transform: translate3d(90vw, 57vh, 89px);
  }
}
.particle-background:nth-child(23) {
  animation: particle-animation-23 60s infinite;
  opacity: 0.48;
  height: 6px;
  width: 6px;
  animation-delay: -4.6s;
  transform: translate3d(27vw, 48vh, 31px);
  background-color: #1282FD;
}

@keyframes particle-animation-24 {
  100% {
    transform: translate3d(71vw, 70vh, 96px);
  }
}
.particle-background:nth-child(24) {
  animation: particle-animation-24 60s infinite;
  opacity: 0.47;
  height: 7px;
  width: 7px;
  animation-delay: -4.8s;
  transform: translate3d(34vw, 62vh, 62px);
  background-color: #1282FD;
}

@keyframes particle-animation-25 {
  100% {
    transform: translate3d(83vw, 43vh, 59px);
  }
}
.particle-background:nth-child(25) {
  animation: particle-animation-25 60s infinite;
  opacity: 0.48;
  height: 7px;
  width: 7px;
  animation-delay: -5s;
  transform: translate3d(5vw, 6vh, 73px);
  background-color: #1282FD;
}

@keyframes particle-animation-26 {
  100% {
    transform: translate3d(39vw, 48vh, 80px);
  }
}
.particle-background:nth-child(26) {
  animation: particle-animation-26 60s infinite;
  opacity: 0.98;
  height: 8px;
  width: 8px;
  animation-delay: -5.2s;
  transform: translate3d(18vw, 60vh, 19px);
  background-color: #1282FD;
}

@keyframes particle-animation-27 {
  100% {
    transform: translate3d(72vw, 25vh, 85px);
  }
}
.particle-background:nth-child(27) {
  animation: particle-animation-27 60s infinite;
  opacity: 0.9;
  height: 8px;
  width: 8px;
  animation-delay: -5.4s;
  transform: translate3d(5vw, 1vh, 2px);
  background-color: #1282FD;
}

@keyframes particle-animation-28 {
  100% {
    transform: translate3d(83vw, 23vh, 94px);
  }
}
.particle-background:nth-child(28) {
  animation: particle-animation-28 60s infinite;
  opacity: 0.67;
  height: 6px;
  width: 6px;
  animation-delay: -5.6s;
  transform: translate3d(87vw, 43vh, 82px);
  background-color: #1282FD;
}

@keyframes particle-animation-29 {
  100% {
    transform: translate3d(60vw, 18vh, 58px);
  }
}
.particle-background:nth-child(29) {
  animation: particle-animation-29 60s infinite;
  opacity: 0.1;
  height: 7px;
  width: 7px;
  animation-delay: -5.8s;
  transform: translate3d(71vw, 10vh, 16px);
  background-color: #1282FD;
}

@keyframes particle-animation-30 {
  100% {
    transform: translate3d(88vw, 36vh, 33px);
  }
}
.particle-background:nth-child(30) {
  animation: particle-animation-30 60s infinite;
  opacity: 0.57;
  height: 10px;
  width: 10px;
  animation-delay: -6s;
  transform: translate3d(60vw, 60vh, 98px);
  background-color: #1282FD;
}

@media only screen and (max-width: 1199px) {
  #root {
    background-position: right -14vw bottom 40%;
  }
}
@media only screen and (max-width: 899px) {
  body {
    min-height: calc(100vh - 40px);
  }
  #root {
    background-image: none;
  }
}
.card-container {
  background: rgba(255, 255, 255, 0.7);
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  border-radius: 16px;
  box-shadow: 0px 0px 20px rgba(28, 28, 28, 0.1);
  margin-bottom: 36px;
}
.card-container.w-550 {
  width: 550px;
}
.card-container.fixed-bottom {
  position: fixed;
  bottom: 16px;
}

@media only screen and (max-width: 599px) {
  .card-container.w-550 {
    width: 100%;
  }
  .card-container.fixed-bottom {
    bottom: 0;
    margin-bottom: 0;
    border-radius: 0;
  }
  .card-stats-item:not(:last-child) {
    margin-bottom: 24px;
  }
}
.button-base {
  font-family: "Open Sans", sans-serif;
  text-transform: unset;
  padding: 8px 25px;
  border-radius: 50px;
  box-shadow: none;
  transition: all 0.2s ease;
}
.button-base * {
  transition: all 0.2s ease;
}
.button-base:hover {
  box-shadow: 0px 5px 15px rgba(28, 28, 28, 0.07);
  border: 1px solid #1282FD;
  background-color: #E8E8E8;
}
.button-base:focus {
  outline: 0;
}

.button-circle {
  min-width: 50px;
  max-width: 50px;
  height: 50px;
  background-color: #1282FD;
  transition: all 0.2s ease;
}
.button-circle svg {
  min-width: 20px;
  height: 20px;
}
.button-circle:hover {
  box-shadow: 0px 5px 15px rgba(28, 28, 28, 0.07);
  background-color: #A94F9C;
  border: none;
}
.button-circle.blue {
  background-color: #1282FD;
}
.button-circle.grey {
  background-color: #E8E8E8;
  color: #979797;
}

.button-chip {
  min-width: 60px;
  border-radius: 8px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 12px;
  background-color: #E8E8E8;
  border: 1px solid #E8E8E8;
  transition: all 0.2s ease;
}
.button-chip:hover {
  box-shadow: 0px 5px 15px rgba(28, 28, 28, 0.07);
  border: 1px solid #1282FD;
}

.button-primary {
  transition: all 1s ease;
}
.button-primary p, .button-primary span {
  color: #FFFFFF;
}
.button-primary.gradient {
  background: linear-gradient(276.1deg, #4D58FF 4.29%, rgba(77, 88, 255, 0) 25.97%), linear-gradient(100.52deg, #DB9BC4 -4.99%, rgba(219, 155, 196, 0) 69.14%), #1282FD;
}
.button-primary.blue {
  background-color: #1282FD;
}
.button-primary:hover {
  box-shadow: 0px 0px 20px rgba(28, 28, 28, 0.1);
  background: #A94F9C;
  border: none;
}
.button-primary:disabled {
  background: #979797;
  cursor: not-allowed;
  pointer-events: unset;
}
.button-primary:disabled p, .button-primary:disabled span {
  color: #D9D9D9;
}

.button-outlined {
  transition: all 1s ease;
  background-color: transparent;
  border: 1px solid #333333;
}
.button-outlined p, .button-outlined span {
  color: #333333;
}
.button-outlined:hover {
  background-color: transparent;
  border: 1px solid #1282FD;
}
.button-outlined:hover p, .button-outlined:hover span {
  color: #1282FD;
}
.button-outlined:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: unset;
  background-color: transparent;
  border: 1px solid #333333;
}
.button-outlined:disabled p, .button-outlined:disabled span {
  color: #333333;
}

.button-icon-base {
  border-radius: 50%;
  padding: 0;
}
.button-icon-base:hover {
  box-shadow: 0px 5px 15px rgba(28, 28, 28, 0.07);
  background-color: #E8E8E8;
}

.button-icon {
  min-width: 30px;
  height: 30px;
  margin: 0 8px;
}
.button-icon svg {
  width: 20px;
  height: 20px;
}

.button-icon-lg {
  min-width: 50px;
  height: 50px;
}
.button-icon-lg svg {
  width: 40px;
  height: 40px;
}
.button-icon-lg svg path {
  fill: #333333;
}

.modal-base,
.wallet-wc-modal--content {
  padding: 25px !important;
  border-radius: 16px !important;
  box-shadow: 0px 0px 20px rgba(28, 28, 28, 0.1) !important;
  width: 400px;
  max-width: 500px;
}
.modal-base.w-450,
.wallet-wc-modal--content.w-450 {
  width: 450px;
}

.modal-base-background,
.wallet-wc-modal--dim {
  background-color: rgba(28, 28, 28, 0.4) !important;
}

.modal-close-button {
  position: absolute;
  top: 20px;
  right: 25px;
  width: 32px;
  height: 32px;
  border: 1px solid #333333;
  border-radius: 50%;
  display: flex;
  min-width: 0px;
  z-index: 1;
  transition: all 0.1s ease;
}
.modal-close-button:hover {
  border-width: 2px;
  background-color: transparent;
}

@media only screen and (max-width: 599px) {
  .modal-base,
  .wallet-wc-modal--content {
    min-width: calc(100vw - 80px);
    max-width: calc(100vw - 80px);
    margin: 0;
  }
  .modal-base.w-450,
  .wallet-wc-modal--content.w-450 {
    min-width: calc(100vw - 80px);
  }
}
.MuiInput-root,
.MuiInputBase-root,
input {
  font-family: "Open Sans", sans-serif !important;
}

.input-base {
  box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.07);
}
.input-base input {
  color: #333333;
}

.input-slippage-container {
  width: 70px;
  height: 32px;
  border-radius: 8px;
}
.input-slippage-container .input-slippage {
  border-radius: 8px;
  padding: 4px 10px;
  font-size: 14px;
  font-weight: 400;
}

.input-search-container {
  background-color: #F6F6F6;
  border-radius: 32px;
  height: 50px;
}
.input-search-container svg {
  padding-right: 24px;
}
.input-search-container svg path {
  fill: #979797;
}
.input-search-container .input-search {
  border: 0;
  padding: 16px 24px;
  font-size: 16px;
  font-weight: 600;
}

.MuiSlider-root p, .MuiSlider-root span {
  font-family: "Open Sans", sans-serif;
}

.tooltip-trigger:hover path, .tooltip-trigger:hover circle {
  transition: all 0.2s ease;
}
.tooltip-trigger:hover:hover path {
  fill: #A94F9C;
}
.tooltip-trigger:hover:hover circle {
  stroke: #A94F9C;
}

.tooltip-base {
  padding: 16px;
  background-color: #8059A7;
  box-shadow: 0px 0px 20px rgba(28, 28, 28, 0.1);
}
.tooltip-base .MuiTooltip-arrow:before {
  background-color: #8059A7;
}
.tooltip-base p, .tooltip-base span {
  color: #FFFFFF;
}

.text-skeleton {
  display: flex;
  background-color: #F6F6F6;
  height: 20px;
  width: 100px;
  border-radius: 8px;
  overflow: hidden;
}
.text-skeleton::after {
  content: "";
  height: 100%;
  width: 100px;
  display: block;
  background: linear-gradient(to right, rgba(230, 230, 230, 0) 0%, rgba(221, 221, 221, 0.4) 42%, rgba(221, 221, 221, 0.4) 62%, rgba(230, 230, 230, 0) 100%);
  animation: shining 1.2s ease-out infinite;
}

@keyframes shining {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(200%);
  }
}
.spinning-logo {
  animation: spin 1.5s 0.3s cubic-bezier(0.17, 0.37, 0.43, 0.67) infinite;
}
.spinning-logo.sm {
  width: 24px;
  height: 24px;
}
.spinning-logo.lg {
  width: 40px;
  height: 40px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.snackbar-container {
  padding: 16px 24px;
  border-radius: 30px;
}

.snackbar-success {
  background-color: #DFF1D9;
  color: #1F8B24;
}
.snackbar-success p, .snackbar-success a {
  color: #1F8B24;
}

.snackbar-error {
  background-color: #FAE1DB;
  color: #D91009;
}

.snackbar-neutral {
  background-color: #F0ECE8;
  color: #605F5C;
}

.table-base {
  font-family: "Open Sans", sans-serif;
  border: 0;
}
.table-base .MuiDataGrid-columnHeader:focus,
.table-base .MuiDataGrid-cell:focus,
.table-base .MuiDataGrid-cell:focus-within {
  outline: none;
}
.table-base .MuiDataGrid-columnHeader--sorted {
  color: #1282FD;
}
.table-base .MuiDataGrid-columnHeader--sorted svg path {
  fill: #1282FD;
}
.table-base .MuiDataGrid-columnSeparator {
  display: none;
}
.table-base .MuiDataGrid-cell {
  border-bottom: none;
}
.table-base .MuiDataGrid-row {
  transition: all 0.5s ease;
}
.table-base .MuiDataGrid-row:hover {
  background-color: #E8E8E8;
}
.table-base .MuiTablePagination-root p, .table-base .MuiTablePagination-root span {
  font-family: "Open Sans", sans-serif;
}

@media only screen and (max-width: 899px) {
  .table-base {
    width: 900px;
  }
  .table-base .MuiDataGrid-footerContainer {
    justify-content: flex-start;
  }
}
@media only screen and (max-width: 599px) {
  .table-base {
    width: 600px;
  }
}
/* Components
================================= */
.logo-dexter {
  width: 45px;
  height: 45px;
  transition: all 1s ease;
}

.logo-dexter-text {
  width: 90px;
  margin-left: 8px;
}

.logo-container:hover .logo-dexter {
  transform: rotate(180deg);
}

.footer-container {
  background-color: rgba(35, 35, 35, 0.74);
}
.footer-container * {
  color: #FFFFFF;
}

.logo-dexter-footer {
  width: 60px;
  height: 60px;
}

.logo-dexter-text-footer {
  width: 120px;
  margin-top: 16px;
}
.logo-dexter-text-footer path {
  fill: #FFFFFF;
}

.external-link-footer {
  text-decoration: none;
  transition: all 0.2s ease;
}
.external-link-footer svg {
  font-size: 26px;
  color: #FFFFFF;
  margin: 0 8px;
}
.external-link-footer svg path {
  transition: all 0.2s ease;
}
.external-link-footer:hover {
  opacity: 0.7;
}

@media only screen and (max-width: 899px) {
  .external-link-footer svg {
    color: #333333;
  }
}
.navigation-link, .MuiTab-root {
  background-color: transparent;
  border-radius: 60px;
  padding: 8px 25px;
  text-align: center;
  text-decoration: none;
}
.navigation-link .navigation-text, .MuiTab-root .navigation-text {
  transition: all 0.2s ease;
}
.navigation-link:hover .navigation-text, .MuiTab-root:hover .navigation-text {
  color: #1282FD;
}

.navigation-link.active, .active.MuiTab-root {
  background-color: #1282FD;
}
.navigation-link.active .navigation-text, .active.MuiTab-root .navigation-text, .navigation-link.active:hover .navigation-text {
  color: #FFFFFF;
}

.navigation-drawer-container {
  padding: 24px;
  box-shadow: 0px 0px 20px rgba(28, 28, 28, 0.1);
}

.MuiTab-root {
  font-family: "Open Sans", sans-serif;
  color: #333333;
  text-transform: none;
  transition: color 0.2s ease;
}
.MuiTab-root.Mui-selected {
  background-color: #1282FD;
  color: #FFFFFF;
}
.MuiTab-root.Mui-selected:hover {
  color: #FFFFFF;
}
.MuiTab-root:hover {
  color: #1282FD;
}

@media only screen and (max-width: 899px) {
  .navigation-link, .MuiTab-root {
    margin: 8px 0;
  }
  .navigation-drawer-item-container {
    min-height: calc(100vh - 55px - 48px);
  }
}
.button-connect-wallet {
  background-color: #D7E8F1;
  border: 1px solid #2C89C7;
}
.button-connect-wallet svg {
  width: 20px;
}
.button-connect-wallet:hover {
  background-color: #D7E8F1;
}
.button-connect-wallet:hover p,
.button-connect-wallet:hover svg path {
  color: #2C89C7;
  fill: #2C89C7;
}

.button-connected-wallet {
  text-transform: unset;
  border-radius: 30px;
}

.button-wallet-options {
  display: unset;
  border-radius: 8px;
  padding: 10px;
  box-shadow: none;
}
.button-wallet-options:hover {
  background-color: #D7E8F1;
  border: none;
  box-shadow: none;
}

.dropdown-connected-wallet {
  box-shadow: 0px 0px 20px rgba(28, 28, 28, 0.1);
  border-radius: 16px;
  min-width: 250px;
  margin-top: 16px;
}
.dropdown-connected-wallet .dropdown-connected-wallet-menu-item:hover {
  background: #E8E8E8;
  transition: all 0.2s ease;
}

.icon-wallet {
  width: 32px;
}

.vertical-divider {
  border-right: 1px solid #2C89C7;
}

.token-selector-form-container {
  background-color: #F6F6F6;
  box-shadow: 0px 5px 15px rgba(28, 28, 28, 0.07);
  padding: 16px;
  border-radius: 8px;
  border: 1.5px solid #F6F6F6;
  position: relative;
  transition: all 0.2s ease;
}
.token-selector-form-container.invalid {
  border: 1.5px solid #EA4343;
}

.button-token-selector {
  border: 1px solid #C8C8C8;
  border-radius: 8px;
  padding: 10px;
  display: unset;
}
.button-token-selector svg path,
.button-token-selector .grey-text {
  fill: #979797;
  color: #979797;
}
.button-token-selector:hover {
  background-color: inherit;
}
.button-token-selector:hover svg path,
.button-token-selector:hover p {
  fill: #333333;
  color: #333333;
}

.token-selector-input {
  text-align: right;
  width: 100%;
}
.token-selector-input:disabled {
  cursor: not-allowed;
}

.token-icon-sm {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.token-icon-lg {
  width: 35px;
  height: 35px;
  margin-right: 16px;
}

.token-options-container {
  max-height: 50vh;
  overflow-y: scroll;
}

.token-options-item {
  cursor: pointer;
  padding: 12px 10px;
  border-radius: 8px;
  transition: all 0.5s ease;
}

.token-options-item:hover {
  background-color: #E8E8E8;
}

@media only screen and (max-width: 899px) {
  .token-selector-form-container {
    padding: 8px;
  }
  .token-price-container {
    bottom: 0;
    right: 8px;
  }
}
@media only screen and (max-width: 599px) {
  .button-token-selector {
    padding: 4px;
  }
  .token-icon-sm {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
}/*# sourceMappingURL=main.css.map */