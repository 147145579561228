.root
  width: 520px
  background: var(--section-background-color)

.form
  padding: 40px 32px 32px 32px

.error
  margin-top: 45px

.formButtons
  border-top: 2px solid var(--background-color)
  padding: 32px 
  
  button
    margin-left: 8px
    margin-right: 8px
    &:first-child
      margin-left: 0
    &:last-child
      margin-right: 0
