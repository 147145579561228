.button-base {
    font-family: $font-primary;
    text-transform: unset;
    padding: 8px 25px;
    border-radius: 50px;
    box-shadow: none;
    transition: all 0.2s ease;

    * {
        transition: all 0.2s ease;
    }

    &:hover {
        box-shadow: $soft-shadow;
        border: 1px solid $electricblue-color;
        background-color: $grey-color;
    }

    &:focus {
        outline: 0;
    }
}

.button-circle {
    min-width: 50px;
    max-width: 50px;
    height: 50px;
    background-color: $electricblue-color;
    transition: all 0.2s ease;

    svg {
        min-width: 20px;
        height: 20px;
    }

    &:hover {
        box-shadow: $soft-shadow;
        background-color: $purple-color;
        border: none;
    }

    &.blue {
        background-color: $electricblue-color;
    }

    &.grey {
        background-color: $grey-color;
        color: $grey-text;
    }
}

.button-chip {
    min-width: 60px;
    border-radius: 8px;
    font-family: $font-primary;
    font-weight: $font-weight-semibold;
    font-size: $font-size-body4;
    background-color: $grey-color;
    border: 1px solid $grey-color;
    transition: all 0.2s ease;

    &:hover {
        box-shadow: $soft-shadow;
        border: 1px solid $electricblue-color;
    }
}

.button-primary {
    transition: all 1s ease;

    p, span {
        color: $white-text;
    }

    &.gradient {
        background: $primary-gradient-color;
    }

    &.blue {
        background-color: $electricblue-color;
    }

    &:hover {
        box-shadow: $primary-shadow;
        background: $purple-color;
        border: none;
    }

    &:disabled {
        background: $grey-text;
        cursor: not-allowed;
        pointer-events: unset;

        p, span {
            color: $darkgrey-color;
        }
    }
}

.button-outlined {
    transition: all 1s ease;
    background-color: transparent;
    border: 1px solid $black-text;

    p, span {
        color: $black-text;
    }

    &:hover {
        background-color: transparent;
        border: 1px solid $electricblue-color;

        p, span {
            color: $electricblue-color;
        }
    }

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: unset;
        background-color: transparent;
        border: 1px solid $black-text;

        p, span {
            color: $black-text;
        }
    }
}

.button-icon-base {
    border-radius: 50%;
    padding: 0;

    &:hover {
        box-shadow: $soft-shadow;
        background-color: $grey-color;
    }
}

.button-icon {
    min-width: 30px;
    height: 30px;
    margin: 0 8px;

    svg {
        width: 20px;
        height: 20px;
    }
}

.button-icon-lg {
    min-width: 50px;
    height: 50px;

    svg {
        width: 40px;
        height: 40px;

        path {
            fill: $black-text;
        }
    }
}
