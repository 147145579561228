.token-selector-form-container {
    background-color: $softgrey-color;
    box-shadow: $soft-shadow;
    padding: 16px;
    border-radius: 8px;
    border: 1.5px solid $softgrey-color;
    position: relative;
    transition: all 0.2s ease;

    &.invalid {
        border: 1.5px solid $red-color;
    }
}

.button-token-selector {
    border: 1px solid $grey-border;
    border-radius: 8px;
    padding: 10px;
    // width: auto;
    display: unset;

    svg path,
    .grey-text {
        fill: $grey-text;
        color: $grey-text;
    }

    &:hover {
        background-color: inherit;

        svg path,
        p {
            fill: $black-text;
            color: $black-text;
        }
    }
}

.token-selector-input {
    @extend .MuiTypography-h3;
    text-align: right;
    // font-weight: $font-weight-semibold;
    // font-size: $font-size-h3;
    width: 100%;

    &:disabled {
        cursor: not-allowed;
    }
}

.token-icon-sm {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}

.token-icon-lg {
    width: 35px;
    height: 35px;
    margin-right: 16px;
}

.token-options-container {
    max-height: 50vh;
    overflow-y: scroll;
}

.token-options-item {
    cursor: pointer;
    padding: 12px 10px;
    border-radius: 8px;
    transition: all 0.5s ease;
}

.token-options-item:hover {
    background-color: $grey-color;
}

@include breakpoint(tablet) {
	.token-selector-form-container {
        padding: 8px;
    }

    .token-price-container {
        bottom: 0;
        right: 8px;
    }
}

@include breakpoint(mobile) {
    .button-token-selector {
        padding: 4px;
    }

    .token-icon-sm {
        width: 16px;
        height: 16px;
        margin-right: 4px;
    }
}
